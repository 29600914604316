















import Vue from "vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

export default Vue.extend ({
    components: {
        PageTitleHeader
    }
})
